import React from "react"

import { BackgroundLayout } from "../components/BackgroundLayout/BackgroundLayout"
import { Distribution } from "../components/Distribution/Distribution"
import { Footer } from "../components/Footer/Footer"
import { Layout } from "../components/Layout/Layout"
import { SEO } from "../components/SEO/Seo"

import "../sass/main.scss"

// markup
const IndexPage = () => {
  return (
    <main>
      <Layout>
        <BackgroundLayout>
          <Distribution isHome={false} />
          <Footer />
        </BackgroundLayout>
      </Layout>
    </main>
  )
}

export default IndexPage

export const Head = () => {
  return <SEO title="Współpraca" pathname="/distribution" />
}
